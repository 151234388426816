import React from "react";

import { Card } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

const SupplierCard = ({ data }) => {
    const { projectId } = useParams();

    return (
        <Card className="mt-4 supplier__card">
            <Link to={`/projects/${projectId}/suppliers/${data.id}`}>
                <Card.Body>
                    <Card.Title className="title">
                        <h4>{data.name}</h4>
                    </Card.Title>
                    <div className="--information">
                        <h5><strong>Location:</strong> <span>{data.location}</span></h5>
                        <h5><strong>Produit et service:</strong></h5>
                        <ul>
                            {data.products.map((product) => <li key={product.id}>{product.name}</li>)}
                        </ul>
                        <h5><strong>Contact:</strong></h5>
                        <ul>
                            <li><strong>Phone</strong>: {data.contact.phone}</li>
                            <li><strong>Profile facebook:</strong> {data.contact.facebook_username}</li>
                        </ul>
                    </div>
                </Card.Body>
            </Link>
        </Card>
    );
}
export default SupplierCard;