export const formatMemberForm = (formData) => {
    return {
        first_name: formData.first_name,
        last_name: formData.last_name,
        roles: formData.roles,
        mail: formData.mail,
        contact: {
            phone: formData.phone,
            facebook_username: formData.facebook_username,
            facebook_link: formData.facebook_link,
        },
        profil_id: formData.profil_id
    }
}

export const formatCashRegisterForm = (formData) => {
    return {
        name: formData.name
    };
}

export const formatCashMovementTypeForm = (formData) => {
    return {
        name: formData.name,
        movement: formData.movement
    };
}

export const formatResetPasswordForm = (formData) => {
    return {
        token: formData.token,
        password: formData.password,
        password_confirmation: formData.password_confirmation
    };
}