const { default: axiosInstance } = require("config/axiosConfig")

const cashRegisterService = {
    /**
     * Récupère la liste des caisses dans un projet.
     * 
     * @param {Int16Array} projectId l'identifiant du projet
     * @param {Int16Array} page la page
     * @returns liste paginé des caisses
     */
    getPaginatedCashRegisterByProject: async (projectId, page) => {
        const response = await axiosInstance.get(`v1/projects/${projectId}/cash-registers`, {
            params: { page: page }
        });
        return response.data;
    },

    /**
     * Enregistre une nouvelle caisse.
     * 
     * @param {Array} data les informations de la nouvelle caisse
     * @param {Int16Array} projectId l'identifiant du projet
     */
    saveNewCashRegister: async (data, projectId) => {
        await axiosInstance.post(`v1/projects/${projectId}/cash-registers`, data);
    },    

    /**
     * Met à jour une caisse.
     * 
     * @param {Array} data les informations de la caisse
     * @param {Int16Array} projectId l'identifiant du projet
     * @param {*} cashRegisterId l'identifiant de la caisse à mettre à jour
     */
    updateCashRegister: async (data, projectId, cashRegisterId) => {
        await axiosInstance.put(`v1/projects/${projectId}/cash-registers/${cashRegisterId}`, data);
    }

}

export default cashRegisterService;