import React, { useCallback, useEffect, useState } from "react";

import SupplierCard from "../../components/suppliers/SupplierCard";

import Select from "react-select";
import { Col, Container, Pagination, Row } from "react-bootstrap";
import productService from "services/productService";
import { Link } from "react-router-dom";
import '../../assets/css/admin/supplier-page.scss';
import supplierService from "services/supplierService";
import { useParams } from "react-router-dom";
import { useProfil } from "contexts/ProfilContext";

const SupplierPage = () => {
    const { projectId } = useParams();
    const { isAdmin } = useProfil();
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({
        per_page: 1,
        total: 0,
        current_page: 1,
        last_page: 1
    });
    const [productOptions, setProductOptions] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [productIds, setProductIds] = useState([]);

    useEffect(() => {
        productService.getProductByProject(projectId)
        .then(result => {
            const options = result.map(item =>  ({
                value: item.id,
                label: item.name
            }));
            setProductOptions(options);
        })
        .catch(error => console.error(error));
        
    }, [projectId]);

    const changeSupplier = useCallback((newPage) => {
        supplierService.getSupplierByProductsPaginated(productIds, newPage, projectId)
        .then(result => {
            setSuppliers(result.data);
            setPagination(result);
        })
        .catch(error => console.error(error));
    }, [productIds, projectId]);
    
    useEffect(() => {
        changeSupplier(page);
    }, [page, changeSupplier])

    const nextPage = () => {
        if (page < pagination.last_page) {
            setPage(page + 1);
        }
    }

    const previousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    }

    const handleChangeOptions = (selectedOptions) => {
        let productId = [];
        if (selectedOptions !== null) {
            selectedOptions.forEach(item => {
                productId.push(item.value);
            });
        }
        setProductIds(productId);
        setSelectedProducts(selectedOptions);
        setPage(1);
    }



    return (
        <>
            <Container>
                <h1>Fournisseurs</h1>
                <Row>
                    <Col md={6}>
                        <Select
                            isMulti
                            options={productOptions}
                            value={selectedProducts}
                            onChange={handleChangeOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                    </Col>
                    <Col md={3}></Col>
                    {isAdmin() && 
                    <Col md={3}>
                        <Link to={`/projects/${projectId}/add-suppliers`}>
                            <button className="asako-btn asako-btn-primary">Nouveau fournisseur</button>
                        </Link>
                    </Col>
                    }
                </Row>
                <Row>
                    {suppliers.map(supplier =>
                        <Col md={4} key={supplier.id}>
                            <SupplierCard data={supplier}/>
                        </Col> 
                    )}
                </Row>
                <Pagination className="mt-4 justify-content-end">
                    <Pagination.Prev onClick={previousPage}/>
                    <Pagination.Next onClick={nextPage}/>
                </Pagination>
            </Container>
        </>
    )
}
export default SupplierPage;