
import React from "react";
import "../../assets/css/client/home/offerBox.css"

const OfferBox = ({ data }) => {
    
    return (
        <>
            <div className="card-offer">
                <div className="card-offer-header">
                    <h1>{data.name}</h1>
                </div>
                <div className="card-offer-body">
                    <ul>
                        {data.products.map((product, index) => 
                            <li key={index}>{product}</li>
                        )}
                    </ul>
                </div>
                <div className="card-offer-footer">
                    <button className="button-offer-order">Commander</button>
                </div>
            </div>
        </>
    )

}

export default OfferBox