import React from "react";

import { Card } from "react-bootstrap";

const CashMovementWidget=({name, value})=>{
    return (
        <Card className="mb-4 cash-movement-widget__card">
            <Card.Body className="body">
                <h4>{name}</h4>
                <h5><strong>{value}</strong></h5>            
            </Card.Body>
        </Card>
    );
}
export default CashMovementWidget;