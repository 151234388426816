import React from "react"

const ContactPhone = () => {
    return (
        <>
            <div className="contact__tab--phone">
                <div>
                    <h3>Veuillez appeler les numéros suivants</h3>
                    <ul>
                        <li>+261 34 61 112 63</li>
                        <li>+261 32 69 864 11</li>
                    </ul>
                </div>
            </div>
        </>
    )
}
export default ContactPhone