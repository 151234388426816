import React from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";

const FormText = ({
    name, placeholder, value, error, handleChange, classIcon, label, autoFocus = false, type = "text", disabled=false, formClass=undefined, tooltipPosition = 'top'
}) => {

    const renderTooltip = (props) => (
        <Tooltip 
        id="error-tooltip" 
        {...props}
        >
            {error}
        </Tooltip>
    );

    return (
        <Form.Group>
            {!!label ? 
            <>
                <Form.Label>
                    <i className={classIcon}></i> <span>{label}</span>
                </Form.Label>
                <Form.Control
                className={formClass}
                type={type}
                name={name}
                placeholder={placeholder}
                value={value || ""}
                onChange={handleChange}
                isInvalid={!!error}
                autoFocus={autoFocus}
                disabled={disabled}
                />
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </>
            :
            <>
                <OverlayTrigger
                    placement={tooltipPosition}
                    overlay={renderTooltip}
                    show={!!error}
                >
                    <Form.Control
                    className={formClass}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    value={value || ""}
                    onChange={handleChange}
                    isInvalid={!!error}
                    autoFocus={autoFocus}
                    disabled={disabled}
                    />
                </OverlayTrigger>
            </>

            }
        </Form.Group>
    )

}

export default FormText;
