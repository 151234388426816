import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import MemberCard from "components/teams/MemberCard";

import { Col, Row } from "react-bootstrap";

import "../../assets/css/teams/members-list.scss";
import NewMemberModal from "components/teams/NewMemberModal";
import teamService from "services/teamService";
import utilService from "services/utilService";
import profilService from "services/profilService";
import { useProfil } from "contexts/ProfilContext";
import MemberLoader from "components/teams/MemberLoader";

const MembersListPage = () => {
    const { isAdmin } = useProfil();
    const { projectId } = useParams();
    const [members, setMembers] = useState([]);
    const [onAddLoad, setOnAddLoad] = useState(false);
    const [onLoad, setOnLoad] = useState(true);

    // Les différents options pour les selects
    const [optionData, setOptionData] = useState({
        roles: [],
        profils: []
    });
    const loadData = useCallback(() => {
        teamService.getMemberByProjectId(projectId)
        .then(result => {
            setMembers(result);
        })
        .catch(error => console.error(error))
        .finally(() => setOnLoad(false));
    }, [projectId]);
    
    useEffect(() => {
        loadData();
    }, [loadData]);

    
    useEffect(() => {
        teamService.getAllRole()
            .then(result => setOptionData(prevOptionData => ({
                ...prevOptionData,
                roles: utilService.configureRoleOption(result)
            })))
            .catch(error => console.error(error));
        profilService.getAllProfil()
            .then(result => setOptionData(prevOptionData => ({
                ...prevOptionData,
                profils: utilService.configureProfilOption(result)
            })))
            .catch(error => console.error(error));
    }, [projectId]);


    return (
        <>
            <div className="members-list-container">
                <h1>Listes des <span>responsables</span></h1>
                {isAdmin() && 
                <NewMemberModal projectId={projectId} loadData={loadData} optionData={optionData} onLoad={setOnAddLoad}/>
                }
                <Row>
                    {onLoad && 
                        <>
                            <Col md={3}><MemberLoader/></Col>
                            <Col md={3}><MemberLoader/></Col>
                            <Col md={3}><MemberLoader/></Col>
                            <Col md={3}><MemberLoader/></Col>
                            <Col md={3}><MemberLoader/></Col>
                            <Col md={3}><MemberLoader/></Col>
                        </>
                    }
                    {members.map(member => 
                    <Col md={3} key={member.id}>
                        <MemberCard member={member} loadData={loadData} optionData={optionData}/>
                    </Col>
                    )}
                    {onAddLoad && <Col md={3}><MemberLoader/></Col>}
                </Row>
            </div>
        </>
    )
}
export default MembersListPage;