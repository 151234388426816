import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import projectService from "services/projectService";

const DeleteProjectModal = ({data, loadData}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleDelete = () => {
        projectService.deleteProject(data.id)
        .then(loadData())
        .then(handleClose())
        .catch(error => console.error(error));
    };


    return (
        <>

            <button className="asako-btn-icon asako-btn-danger" onClick={handleShow}>
                <i className="bi bi-trash-fill"></i>
            </button>

            <Modal show={show} onHide={handleClose} centered dialogClassName="asako__modal">
                <Modal.Header className="header">
                    <Modal.Title> {data.name} </Modal.Title>
                    <i className="bi bi-x-lg" onClick={handleClose}></i>
                </Modal.Header>
                <Modal.Body className="project__modal--body">
                    Le projet <span> {data.name} </span> va ếtre supprimé de manière définitive?
                </Modal.Body>
                <Modal.Footer>
                    <button className="asako-btn asako-btn-secondary" onClick={handleClose}>Fermer</button>
                    <button className="asako-btn asako-btn-danger" onClick={handleDelete} type="submit">Supprimer</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DeleteProjectModal;