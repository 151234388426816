const utilService = {
    configureRoleOption (roles) {
        const roleOptions = [];
        roles.forEach(role => {
            roleOptions.push({value: role.id, label: role.name})
        });
        return roleOptions;
    },

    configureProfilOption (profils) {
        const profilOptions = [];
        profils.forEach(profil => {
            profilOptions.push({value: profil.id, label: profil.name})
        });
        return profilOptions;
    },

}

export default utilService;