import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Form, Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useProfil } from "contexts/ProfilContext";
import "../../../assets/css/projects/cashing/cash-movement-page.scss";
import CashMovementLine from "components/projects/cashing/CashMovementLine";
import cashingService from "services/cashingService";
import AddCashMovementLine from "components/projects/cashing/AddCashMovementLine";
import AsakoPagination from "components/AsakoPagination";
import FormMultiSelect from "components/FormMultiSelect";
import FormText from "components/FormText";
import CashMovementDashboard from "components/projects/cashing/CashMovementDashboard";
import CashRegisterFlow from "components/projects/cashing/CashRegisterFlow";

const CashMovement = () => {
    const { isAdmin } = useProfil();
    const { projectId } = useParams();
    const [cashMovements, setCashMovements] = useState([]);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({
        per_page: 1,
        total: 0,
        current_page: 1,
        last_page: 1
    });
    const [options, setOptions] = useState({
        members: [],
        suppliers: [],
        cash_registers: [],
        cash_movements_types: []
    });
    const [filterOptions, setFilterOptions] = useState({});
    const [filters, setFilters] = useState({
        start_date: "",
        end_date: "",
        cash_registers: [],
        cash_movements_types: [],
    });

    const [selectedFilters, setSelectedFilters] = useState({
        cash_registers: [],
        cash_movements_types: [],
    });

    const handleChangeFilter = (event) => {
        const { name, value } = event.target;
        setFilters({
            ...filters,
            [name]: value
        });
    };

    const handleChangeMultipleFilter = (selected, name) => {
        const temp = selected ? selected.map(item => item.value) : [];
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: temp
        }));
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            [name]: selected 
        }));
    };

    const [showFilter, setShowFilter] = useState(true);
    const toggleFilter = () => setShowFilter(prevShowFilter => !prevShowFilter);

    const loadData = useCallback(() => {
        cashingService.getCashMovementFilteredByProject(projectId, filters, page)
        .then(result => {
            setCashMovements(result.data);
            setPagination(result);
        })
        .catch(error => console.error('Error fetching cash movements:', error));
    }, [projectId, filters, page]);

    const loadOptions = useCallback(() => {
        cashingService.getCashMovementsOptionsByProject(projectId)
        .then(result =>{
            setOptions({
                members: result.members,
                suppliers: result.suppliers,
                cash_registers: result.cash_registers,
                cash_movements_types: result.cash_movements_types,
            });
            setFilterOptions({
                cash_registers: result.cash_registers.map(cashRegister => ({ label: cashRegister.name, value: cashRegister.id })),
                cash_movements_types: result.cash_movements_types.map(cashMovementType => ({ label: cashMovementType.name, value: cashMovementType.id }))
            });
        })
        .catch(error => console.error(error));
    }, [projectId]);

    useEffect(() => {
        loadOptions();
        loadData();
    }, [projectId, page, loadData, loadOptions]);

    const handleFilter = (event) => {
        event.preventDefault();
        loadData();
    };

    return (
        <>
            <Row>
                <Col md={8}>
                    <CashRegisterFlow loadData={loadData}/>
                </Col>
                <Col md={4}>
                    <CashMovementDashboard loadData={loadData} />
                </Col>
            </Row>

            <Card className="mb-4 cash-movement-filter__card">
                <Card.Header>
                    <h4 onClick={toggleFilter}>Filtre</h4>
                </Card.Header>
                <Card.Body className={`body ${showFilter ? 'show' : 'hide'}`}>
                    <Form onSubmit={handleFilter}>
                        <Row>
                            <Col md={4}>
                                <FormText 
                                    type="date" 
                                    label={"Date avant:"}
                                    name={"start_date"}
                                    value={filters.start_date}
                                    handleChange={handleChangeFilter}
                                />
                                <FormText 
                                    type="date" 
                                    label={"Date après:"}
                                    name={"end_date"}
                                    value={filters.end_date}
                                    handleChange={handleChangeFilter}
                                />
                            </Col>
                            <Col md={4}>
                                <FormMultiSelect 
                                    name={"cash_registers"}
                                    label={"Caisse"}
                                    options={filterOptions.cash_registers}
                                    handleChange={handleChangeMultipleFilter}
                                    placeholder={"Caisse"}
                                    formClass={"multi-select"}
                                    value={selectedFilters.cash_registers}
                                />
                            </Col>
                            <Col md={4}>
                                <FormMultiSelect
                                    name={"cash_movements_types"}
                                    label={"Type de Mouvement"}
                                    options={filterOptions.cash_movements_types}
                                    handleChange={handleChangeMultipleFilter}
                                    placeholder={"Type de mouvement"}
                                    value={selectedFilters.cash_movements_types}
                                    formClass={"multi-select"}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
            
            <Card className="cash-movement__card">
                <Card.Body className="body">
                    <Card.Title>
                        <h1>Mouvement de Caisse</h1>
                    </Card.Title>
                    <ul>
                        <li><strong>Qte: </strong>Quantité</li>
                        <li><strong>PU: </strong>Prix Unitaire</li>
                    </ul>
                    <Table bordered striped className="cash-movement__table">
                        <thead>
                            <tr>
                                <th className="movement_date">Date</th>
                                <th className="responsable">Responsable</th>
                                <th className="cash_register">Caisse</th>
                                <th className="type">Type</th>
                                <th className="product">Produit</th>
                                <th className="supplier">Fournisseur</th>
                                <th className="quantity">Qte</th>
                                <th className="unit_price">(Ar) PU</th>
                                <th className="total_inflow">(Ar) Entrée totale</th>
                                <th className="total_outflow">(Ar) Sortie totale</th>
                                {isAdmin() && 
                                <th>Action</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {isAdmin() && 
                            <tr className="input-line">
                                <AddCashMovementLine options={options} loadData={loadData}/>
                            </tr>
                            }
                            {cashMovements.map((cashMovement) => (
                                <CashMovementLine key={cashMovement.id} cashMovement={cashMovement} options={options} loadData={loadData}/>
                            ))}
                        </tbody>
                    </Table>
                    <AsakoPagination page={page} setPage={setPage} pagination={pagination}/>
                </Card.Body>
            </Card>
        </>
    );
};

export default CashMovement;
