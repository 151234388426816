import React from "react";

import { Pagination } from "react-bootstrap";

const AsakoPagination = ({page, setPage, pagination}) => {

    const nextPage = () => {
        if (page < pagination.last_page) {
            setPage(page + 1);
        }
    }

    const previousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    }

    return (
        <div className="pagination">
            <Pagination className="mt-4 justify-content-end">
                <Pagination.Prev onClick={previousPage} disabled={page === 1} >
                    Précedent
                </Pagination.Prev>
                {[...Array(pagination.last_page).keys()].map(num => (
                    <Pagination.Item key={num + 1} active={num + 1 === page} onClick={() => setPage(num + 1)}>
                        {num + 1}
                    </Pagination.Item>
                ))}
                <Pagination.Next onClick={nextPage} disabled={page === pagination.last_page} >
                    Suivant
                </Pagination.Next>
            </Pagination>
        </div>
    );
}
export default AsakoPagination;