import React from "react";

import { Card, Col, Row } from 'react-bootstrap';

import CashRegisterCRUD from "components/projects/cashing/CashRegisterCRUD";
import CashMovementTypeCRUD from "components/projects/cashing/CashMovementTypeCRUD";
import "../../../assets/css/projects/cashing/cash-movement-parameter-page.scss";

const CashMovementParameterPage = () => {
    return (
        <>
            <Row className="cash-movement-parameter">
                <Col md={6}>
                    <Card className="card">
                        <Card.Body className="body">
                            <CashRegisterCRUD />
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="card">
                        <Card.Body className="body">
                            <CashMovementTypeCRUD />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );

}
export default CashMovementParameterPage;