import React from "react";

import Navbar from '../templates/shared/Navbar';
import Sidebar from '../templates/shared/Sidebar';
import Footer from '../templates/shared/Footer';
import "../assets/css/admin/template.scss";

const ProjectLayout = ({ children }) => {

    return (
        <>
            <div className="container-scroller">
                <Navbar />
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar />
                        <div className="main-panel">
                            <div className="content-wrapper">
                                { children }
                            </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}
export default ProjectLayout;