import React from "react";
import { Carousel } from "react-bootstrap"
import '../../assets/css/client/home/homeCarousel.css'

const HomeCarousel = () => {
    const baseImg = "/home/"
    const carouselImages = ["dance.jpg"]

    return (
        <Carousel>
            {carouselImages.map((image, index) => 
            <Carousel.Item key={index} className="image-carousel">
                <img src={`${baseImg}${image}`} alt="carousel" />
                <Carousel.Caption>
                <h3>First slide label</h3>
                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </Carousel.Caption>
            </Carousel.Item>
            )}
        </Carousel>
    )
}

export default HomeCarousel