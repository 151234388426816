const { default: axiosInstance } = require("config/axiosConfig")

const cashRegisterService = {
    /**
     * Récupère la liste des types de mouvements de caisse.
     * 
     * @param {Int16Array} projectId l'identifiant du projet
     * @param {Int16Array} page la page
     * @returns liste paginé des types de mouvements de caisse
     */
    getPaginatedCashMovementTypeByProject: async (projectId, page) => {
        const response = await axiosInstance.get(`v1/projects/${projectId}/cash-movement-types`, {
            params: { page: page }
        });
        return response.data;
    },

    /**
     * Enregistre un nouveau type de mouvement de caisse dans un projet.
     * 
     * @param {Array} data les informations du types de moumvement
     * @param {Int16Array} projectId l'identifiant du projet
     */
    saveNewCashMovementType: async (data, projectId) => {
        await axiosInstance.post(`v1/projects/${projectId}/cash-movement-types`, data);
    },    

    /**
     * Met à jour un type de mouvement de caisse 
     * 
     * @param {Array} data les informations du types de moumvement
     * @param {Int16Array} projectId l'identifiant du projet
     * @param {*} cashMovementTypeId l'identifiant du type de mouvement à mettre à jour
     */
    updateCashMovementType: async (data, projectId, cashMovementTypeId) => {
        await axiosInstance.put(`v1/projects/${projectId}/cash-movement-types/${cashMovementTypeId}`, data);
    }

}

export default cashRegisterService;