import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "../../../assets/css/client/auth/login.scss"; 
import clientService from "services/clientService";
import { useParams, useHistory } from "react-router-dom";
import { formatResetPasswordForm } from "utils/FormFormatter";

const DefinePassword = () => {
    const history = useHistory();
    const { token } = useParams();
    const [formData, setFormData] = useState({
        token: token,
        password: "",
        password_confirmation: ""
    });
    
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]:value
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formattedData = formatResetPasswordForm(formData);
        clientService.resetPassword(formattedData)
        .then(() => {
            history.push(`/projects`)
        })
        .catch(error => console.error(error));
    }
    
    return (
    <div className="login__container">
        <div className="login__image">
            <img src="./assets/img/login.png" alt="login background"/>
        </div>
        <div className="login__form--container">
            <div className="parent">
                <h2 className="text-center mb-4">Nouveau Mot de Passe</h2>
                <Form onSubmit={handleSubmit}>

                    <Form.Group controlId="formBasicPassword" className="login__form--input">
                    <label>
                        <i className="bi bi-lock"></i>
                        Mot de passe
                    </label>
                    <Form.Control
                        type="password"
                        name="password"
                        placeholder="Entrer votre mot de passe"
                        value={formData.password}
                        onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword" className="login__form--input">
                    <label>
                        <i className="bi bi-lock"></i>
                        Confirmation du Mot de passe
                    </label>
                    <Form.Control
                        type="password"
                        name="password_confirmation"
                        placeholder="Entrer votre mot de passe"
                        value={formData.password_confirmation}
                        onChange={handleChange}
                        />
                    </Form.Group>
                    <button className="login__submit" type="submit">
                        Valider
                    </button>
                </Form>
                <hr />
                <p className="nousContacter">Veuillez définir votre  <span>nouveau mot de passe</span></p>
            </div>
        </div> 
    </div>
  );
};

export default DefinePassword;
