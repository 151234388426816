import FormText from "components/FormText";
import React from "react";
import { showError } from "utils/error";

const CashRegisterForm = ({formData, setFormData, errors}) => {
    
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    return (
        <FormText
        autoFocus={true}
        label={"Nom"}
        name={"name"}
        placeholder={"Nom"}
        value={formData.name}
        handleChange={handleChange}
        error={showError(errors, 'name')}
        />
    )
}
export default CashRegisterForm;