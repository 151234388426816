import React, { useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import cashingService from "services/cashingService";

const RemoveCashMovementModal = ({ cashMovement, loadData }) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const handleDelete = () => {
        cashingService.deleteCashMovement(cashMovement.projectId, cashMovement.id)
        .then(result => {
            loadData();
            handleClose();
        })
        .catch(error => console.error(error));
    }
    
    return (
        <>
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={tooltipDelete}
            >
                <button
                className="asako-btn-icon asako-btn-danger"
                onClick={handleShow}
                >
                    <i className="bi bi-trash-fill"></i>
                </button>
            </OverlayTrigger>

            <Modal className="asako__modal" show={show} centered>
                <Modal.Header className="header">
                    <h4>Suppression d'une ligne dans les mouvements de caisse</h4>
                    <i className="bi bi-x-lg" onClick={handleClose}></i>
                </Modal.Header>
                <Modal.Body className="body">
                    <p>
                        Vous êtes sur le point de supprimer définitivement une ligne de mouvement de caisse.
                    </p>
                </Modal.Body>
                <Modal.Footer className="footer">
                    <button className="asako-btn asako-btn-secondary" onClick={handleClose}>Annuler</button>
                    <button className="asako-btn asako-btn-danger" onClick={handleDelete}>Danger</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const tooltipDelete = (
    <Tooltip id="tooltip">
        Effacer
    </Tooltip>
);

export default RemoveCashMovementModal;