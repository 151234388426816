import React, { createContext, useContext, useState } from 'react';

const ProfilContext = createContext();

export const ProfilProvider = ({ children }) => {
  const PROFIL_ADMIN_ID = 1;

  const [profil, setProfil] = useState({
    id: undefined,
    name: undefined
  });

  const isAdmin = (profil_id = undefined) => {
    if (profil_id === undefined) return profil.id === PROFIL_ADMIN_ID;
    return profil_id === PROFIL_ADMIN_ID
  }

  return (
    <ProfilContext.Provider value={{ profil, setProfil, isAdmin }}>
      {children}
    </ProfilContext.Provider>
  );
};

export const useProfil = () => useContext(ProfilContext);
