import FormSelect from "components/FormSelect";
import FormText from "components/FormText";
import React from "react";
import { cashMovementTypeValue } from "utils/dict";
import { showError } from "utils/error";

const CashMovementTypeForm = ({formData, setFormData, errors}) => {

    const movementOptions = Object.entries(cashMovementTypeValue).map(([key, value]) => ({
        value: key,
        label: value
    }));


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }
    
    return (
        <>
            <FormText
            autoFocus={true}
            label={"Nom"}
            name={"name"}
            placeholder={"Nom"}
            value={formData.name}
            handleChange={handleChange}
            error={showError(errors, 'name')}
            />
            <FormSelect
            label={"Type"}
            name={"movement"}
            placeholder={"type"}
            value={formData.movement}
            options={movementOptions}
            handleChange={handleChange}
            error={showError(errors, 'movement')}
            />
        </>
    )
}
export default CashMovementTypeForm;