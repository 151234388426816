import React from "react"
import Header from "../../../components/home/Header"
import HomeCarousel from "../../../components/home/HomeCarousel"
import "../../../assets/css/client/home/home.scss";
import Offer from "../../../components/home/Offer";
import Contact from "../../../components/home/Contact";


const Home = () => {

    return (
        <>  
            <Header />
            <div className="content">
                <HomeCarousel />
                <Offer />
            </div>
            <Contact />
        </>
    )

}
export default Home