import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal, OverlayTrigger } from "react-bootstrap";
import supplierService from "services/supplierService";
import CreatableSelect from "react-select/creatable";
import productService from "services/productService";
import DeletingNoticeTooltip from "components/DeletingNoticeTooltip";
import { useParams } from "react-router-dom";

const AddSupplierProductModal = ({loadData, id}) => {
    const { projectId } = useParams();
    const [productOptions, setProductOptions] = useState([]);
    const [errors, setErrors] = useState({});
    const [formNotice, setFormNotice] = useState("");
    const [formData, setFormData] = useState({
        name: null,
        description: "",
        unit_price: 0,
        notices: []
    });

    const showError = (field) => {
        if (errors[field] && errors[field].length > 0) {
          return errors[field][0]; 
        }
        return null;
    };

    useEffect(() => {
        productService.getProductByProject(projectId)
        .then(result => {
            const options = result.map(item =>  ({
                value: item.name,
                label: item.name
            }));
            setProductOptions(options);
        })
        .catch(error => console.error(error));
    }, [projectId]);

    const handleSelectChange = (selectedOption, actionMeta) => {
        setFormData({
            ...formData,
            name: selectedOption
        });
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const initValue = () => {
        setFormData({
            name: null,
            description: "",
            unit_price: 0,
            notices: []
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            supplier_id: id,
            name: formData.name?.value || "",
            unit_price: formData.unit_price,
            description: formData.description,
            notices: formData.notices,
            project_id: projectId
        }
        supplierService.addSupplierProduct(data)
        .then(result => {
            loadData();
            initValue();
            handleClose();
        })
        .catch(error => setErrors(error.response.data.errors));
    }

    const deleteNotice = (index) => {
        const temp = [...formData.notices];
        temp.splice(index, 1);
        setFormData({
            ...formData,
            notices: temp
        });
    }

    const addNotice = () => {
        if (formNotice === "") {
            return;
        }
        setFormData({
            ...formData,
            notices: [...formData.notices, formNotice]
        })
        setFormNotice("");
    }

    return (
        <>
            <button className="asako-btn-icon asako-btn-primary mr-2" onClick={handleShow}>
                <i className="bi bi-plus-circle-fill"></i>
            </button>

            <Modal show={show} onHide={handleClose} centered dialogClassName="asako__modal">
                <Form onSubmit={handleSubmit}>
                    <Modal.Header className="header">
                        <Modal.Title>Ajout de service</Modal.Title>
                        <i className="bi bi-x-lg" onClick={handleClose}></i>
                    </Modal.Header>
                    <Modal.Body>

                        <Form.Group controlId="StepSupplierInformationName" className="mb-2">
                            <Form.Label>
                                <span> <i className="bi bi-cart"></i> </span>
                                Produit
                            </Form.Label>
                            <CreatableSelect
                                isClearable
                                options={productOptions}
                                onChange={handleSelectChange}
                                name="name"
                                value={formData.name}
                                placeholder="Produit"
                                autoFocus
                                className={!!showError('name') && "form-has-error"}
                                formatCreateLabel={(inputValue) => `Nouveau "${inputValue}"`}
                                />
                            <div className="form-error">
                                {showError('name')}
                            </div>
                        </Form.Group>

                        <Form.Group controlId="StepSupplierInformationName" className="mb-2">
                            <Form.Label>
                                <span> <i className="bi bi-info-circle"></i> </span>
                                Description
                            </Form.Label>
                            <Form.Control
                            as="textarea"
                            rows={6}
                            placeholder="description"
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                            isInvalid={!!showError('description')}
                            />
                            <Form.Control.Feedback type="invalid">
                                {showError('description')}
                            </Form.Control.Feedback>
                        </Form.Group>
                        
                        <Form.Group controlId="StepSupplierInformationUnitPrice" className="mb-2">
                            <Form.Label>
                                <span> <i className="bi bi-money"></i> </span>
                                Prix
                            </Form.Label>
                            <InputGroup >
                                <Form.Control
                                type="number"
                                placeholder="Prix"
                                name="unit_price"
                                value={formData.unit_price}
                                onChange={handleInputChange}
                                min={0}
                                step={2}
                                pattern="\d*"
                                isInvalid={!!showError('unit_price')}
                                />
                                <InputGroup.Text id="InputGroupUnitPrice">Ar</InputGroup.Text>
                                <Form.Control.Feedback type="invalid">
                                    {showError('unit_price')}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group controlId="NoticeForm" className="mb-3 form-notice">
                            <Form.Label>
                                <span> 
                                    <i className="bi bi-chat-dots"></i> Vos avis
                                </span>
                            </Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control
                                value={formNotice}
                                onChange={e => setFormNotice(e.target.value)}
                                placeholder="Nouvel avis ..."
                                autoFocus
                                />
                                <Button variant="outline-secondary" id="button-addon2" onClick={addNotice}>
                                Ajouter
                                </Button>
                            </InputGroup>
                            <ul>
                                {formData.notices.map((item, index) => 
                                    <li key={index}> 
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={DeletingNoticeTooltip}
                                    >
                                        <i className="bi bi-x-lg" onClick={() => deleteNotice(index)}></i> 
                                    </OverlayTrigger>
                                    {item}
                                    </li>
                                )}
                            </ul>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="asako-btn asako-btn-secondary" onClick={handleClose} type="button">
                            Annuler
                        </button>
                        <button className="asako-btn asako-btn-primary" type="submit">
                            Ajouter
                        </button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default AddSupplierProductModal;