import FormText from "components/FormText";
import FormMultiSelect from "components/FormMultiSelect";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import teamService from "services/teamService";
import utilService from "services/utilService";
import { formatMemberForm } from "utils/FormFormatter";
import {useParams} from "react-router-dom";
import profilService from "services/profilService";
import FormSelect from "components/FormSelect";

const ModifyMemberModal = ({ member, loadData, optionData }) => {
    const { projectId } = useParams();

    // State pour montrer ou non le modal
    const [show, setShow] = useState(false);

    // Les informations controllées du formulaire
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        roles: null,
        phone: "",
        mail: "",
        facebook_link: "",
        facebook_username: "",
        profil_id: "",
    });

    // Les options selectionnés dans les multi-selects
    const [selectedOptions, setSelectedOptions] = useState({
        roles: [],
    });

    // Les erreurs de validation du formulaire
    const [errors, setErrors] = useState({});

    // Changer la valeur d'une input
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    // Changer la valeur du select role
    const handleRolesChange = (selected) => {
        const temp = selected ? selected.map(item => item.value) : [];
        setFormData({
            ...formData,
            roles: temp
        });
        setSelectedOptions({
            ...selectedOptions,
            roles: selected 
        })
    }

    // Montrer le modal
    const handleShow = () => {
        setShow(true);
        setFormData({
            ...formData,
            first_name: member.first_name,
            last_name: member.last_name,
            roles: member.roles.map(role => role.id),            
            phone: member.contact.phone,
            mail: member.mail,
            facebook_username: member.contact.facebook_username,
            facebook_link: member.contact.facebook_link,
            profil_id: member.profil_id,
        });
        setSelectedOptions({
            ...selectedOptions,
            roles: member.roles.map(role => ({ value: role.id, label: role.name })),
        })
    }

    // Fermer le modal
    const handleClose = () => setShow(false);

    // Submitter le formulaire
    const handleSubmit = (e) => {
        e.preventDefault();
        teamService.updateMember(formatMemberForm(formData), member.id, projectId)
        .then(result => {
            handleClose();
            loadData();
        })
        .catch(error => setErrors(error.response.data.errors));
    }

    // Affiche l'erreur du field désigné
    const showError = (field) => {
        return errors[field] && errors[field].length > 0 ? errors[field][0] : null;
    };


    return (
        <>
            <button 
            className="asako-btn-icon asako-btn-primary"
            onClick={handleShow}
            >
                <i className="bi bi-pencil-fill"></i>
            </button>
        
            <Modal show={show} centered dialogClassName="asako__modal new-member__modal" size={"xl"}>
                <Modal.Header className="header">
                    <Modal.Title>
                        <h4>Ajouter un nouveau membre</h4>
                    </Modal.Title>
                    <i className="bi bi-x-lg" onClick={handleClose}></i>
                </Modal.Header>
                <Modal.Body className="form">
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={6} className="mb-3">
                                <FormText
                                name={"first_name"}
                                label={"Prénom"}
                                classIcon={"bi bi-person-fill"}
                                placeholder={"Prénom"}
                                value={formData.first_name}
                                handleChange={handleInputChange}
                                error={showError("first_name")}
                                autoFocus={true}
                                />
                            </Col>

                            <Col md={6} className="mb-3">
                                <FormText
                                name={"last_name"}
                                label={"Nom"}
                                classIcon={"bi bi-person-fill"}
                                placeholder={"Nom"}
                                value={formData.last_name}
                                handleChange={handleInputChange}
                                error={showError("last_name")}
                                autoFocus={true}
                                />
                            </Col>
                            

                            <Col md={10} className="mb-3">
                                <FormMultiSelect
                                name={"roles"}
                                label={"Poste"}
                                classIcon={"bi bi-person-badge"}
                                placeholder={"Poste"}
                                value={selectedOptions.roles}
                                options={optionData.roles}
                                handleChange={handleRolesChange}
                                error={showError("roles")}
                                />
                            </Col>

                            <Col md={2} className="mb-3">
                                <FormSelect
                                name={"profil_id"}
                                label={"Profil"}
                                classIcon={"bi bi-person-badge"}
                                placeholder={"Profil"}
                                error={showError("profil_id")}
                                options={optionData.profils}
                                handleChange={handleInputChange}
                                value={formData.profil_id}
                                />
                            </Col>

                            
                            <Col md={6} className="mb-3">
                                <FormText
                                name={"phone"}
                                label={"Téléphone"}
                                classIcon={"bi bi-phone-fill"}
                                placeholder={"Téléphone"}
                                value={formData.phone}
                                handleChange={handleInputChange}
                                error={showError("contact.phone")}
                                />
                            </Col>

                            <Col md={6} className="mb-3">
                                <FormText
                                name={"mail"}
                                label={"Addresse éléctronique"}
                                classIcon={"bi bi-envelope-fill"}
                                placeholder={"Addresse éléctronique"}
                                value={formData.mail}
                                handleChange={handleInputChange}
                                error={showError("contact.mail")}
                                />
                            </Col>

                            <Col md={6} className="mb-3">
                                <FormText
                                name={"facebook_username"}
                                label={"Nom d'utilisateur facebook"}
                                classIcon={"bi bi-facebook"}
                                placeholder={"Nom d'utilisateur facebook"}
                                value={formData.facebook_username}
                                handleChange={handleInputChange}
                                error={showError("contact.facebook_username")}
                                />
                            </Col>
                            
                            <Col md={6} className="mb-3">
                                <FormText
                                name={"facebook_link"}
                                label={"Lien du compte facebook"}
                                classIcon={"bi bi-hash"}
                                placeholder={"Lien du compte facebook"}
                                value={formData.facebook_link}
                                handleChange={handleInputChange}
                                error={showError("contact.facebook_link")}
                                />
                            </Col>

                        </Row>

                        <button className="asako-btn asako-btn-primary w-50">Enregistrer</button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ModifyMemberModal;