import React from "react";

const TaskPage = () => {

    return (
        <>
            <h1>Tâches</h1>
        </>
    )
}
export default TaskPage;