import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import '../../assets/styles/sidebar.scss';
import { useParams } from "react-router-dom";
import { useProfil } from 'contexts/ProfilContext';

const Sidebar = () => {
  const { projectId } = useParams();
  const { isAdmin } = useProfil();
  const [menuState, setMenuState] = useState({});
  const location = useLocation();

  const toggleMenuState = (menuStateKey) => {
    if (menuState[menuStateKey]) {
      setMenuState(prevState => ({ ...prevState, [menuStateKey]: false }));
    } else if (Object.keys(menuState).length === 0) {
      setMenuState(prevState => ({ ...prevState, [menuStateKey]: true }));
    } else {
      setMenuState(prevState => {
        const newState = Object.keys(prevState).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {});
        return { ...newState, [menuStateKey]: true };
      });
    }
  };

  useEffect(() => {
    const onRouteChanged = () => {
      document.querySelector('#sidebar').classList.remove('active');
      setMenuState(prevState => {
        const newState = Object.keys(prevState).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {});
        
        const dropdownPaths = [
          { path: '/apps', state: 'appsMenuOpen' },
          { path: '/basic-ui', state: 'basicUiMenuOpen' },
          { path: '/form-elements', state: 'formElementsMenuOpen' },
          { path: '/tables', state: 'tablesMenuOpen' },
          { path: '/icons', state: 'iconsMenuOpen' },
          { path: '/charts', state: 'chartsMenuOpen' },
          { path: '/user-pages', state: 'userPagesMenuOpen' },
          { path: '/error-pages', state: 'errorPagesMenuOpen' },
        ];

        dropdownPaths.forEach(obj => {
          if (location.pathname.startsWith(obj.path)) {
            newState[obj.state] = true;
          }
        });

        return newState;
      });
    };

    onRouteChanged();
  }, [location.pathname]);

  useEffect(() => {
    const body = document.querySelector('body');
    const handleMouseOver = (el) => {
      if (body.classList.contains('sidebar-icon-only')) {
        el.classList.add('hover-open');
      }
    };
    const handleMouseOut = (el) => {
      if (body.classList.contains('sidebar-icon-only')) {
        el.classList.remove('hover-open');
      }
    };

    document.querySelectorAll('.sidebar .nav-item').forEach(el => {
      el.addEventListener('mouseover', () => handleMouseOver(el));
      el.addEventListener('mouseout', () => handleMouseOut(el));
    });

    return () => {
      document.querySelectorAll('.sidebar .nav-item').forEach(el => {
        el.removeEventListener('mouseover', () => handleMouseOver(el));
        el.removeEventListener('mouseout', () => handleMouseOut(el));
      });
    };
  }, []);

  const isPathActive = (path) => location.pathname.startsWith(path);

  return (
    <nav className="sidebar sidebar-offcanvas my-sidebar" id="sidebar">
      <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
        <a className="sidebar-brand brand-logo" href="index.html">
          <img src={"../../assets/images/logo.svg"} alt="logo" />
        </a>
        <a className="sidebar-brand brand-logo-mini pt-3" href="index.html">
          <img src={"../../assets/images/logo-mini.svg"} alt="logo" />
        </a>
      </div>
      <ul className="nav">

        {/* Add menu items as per the class component */}
        <li className={isPathActive(`/projects/${projectId}/dashboard`) ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to={`/projects/${projectId}/dashboard`}>
            <i className="bi bi-briefcase menu-icon"></i>
            <span className="menu-title">Tableaux de bord</span>
          </Link>
        </li>
        <li className={isPathActive(`/projects/${projectId}/suppliers`) ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to={`/projects/${projectId}/suppliers`}>
            <i className="bi bi-people menu-icon"></i>
            <span className="menu-title">Fournisseurs</span>
          </Link>
        </li>
        <li className={isPathActive(`/projects`) ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to={`/projects`}>
            <i className="bi bi-people menu-icon"></i>
            <span className="menu-title">Mes Projets</span>
          </Link>
        </li>
        <li className={isPathActive(`/projects/${projectId}/teams`) ? 'nav-item active' : 'nav-item'}>
          <div className={menuState.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('basicUiMenuOpen')} data-toggle="collapse">
            <i className="mdi mdi-crosshairs-gps menu-icon"></i>
            <span className="menu-title">Équipes</span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={menuState.basicUiMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <Link className={isPathActive(`/projects/${projectId}/teams`) ? 'nav-link active' : 'nav-link'} to={`/projects/${projectId}/teams`}>
                  Membres
                </Link>
              </li>
              <li className="nav-item">
                <Link className={isPathActive(`/projects/${projectId}/tasks`) ? 'nav-link active' : 'nav-link'} to={`/projects/${projectId}/tasks`}>
                  Tâches
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>

        {isAdmin() ? 
        <li className={isPathActive(`/projects/${projectId}/cash-movements`) ? 'nav-item active' : 'nav-item'}>
          <div className={menuState.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('basicUiMenuOpen')} data-toggle="collapse">
            <i className="mdi mdi-crosshairs-gps menu-icon"></i>
            <span className="menu-title">Caisse</span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={menuState.basicUiMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <Link className={isPathActive(`/projects/${projectId}/cash-movements`) ? 'nav-link active' : 'nav-link'} to={`/projects/${projectId}/cash-movements`}>
                  Mouvement de caisse
                </Link>
              </li>
              <li className="nav-item">
                <Link className={isPathActive(`/projects/${projectId}/cash-movements`) ? 'nav-link active' : 'nav-link'} to={`/projects/${projectId}/cash-movement-parameters`}>
                  Paramétrage des caisses
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>
        :
        <li className={isPathActive(`/projects/${projectId}/cash-movements`) ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to={`/projects/${projectId}/cash-movements`}>
            <i className="bi bi-people menu-icon"></i>
            <span className="menu-title">Mouvement de caisse</span>
          </Link>
        </li>
        }
      </ul>
    </nav>
  );
};

export default Sidebar;
