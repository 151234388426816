import React from "react";
import { Form } from "react-bootstrap";

const FormSelect = ({
    name, placeholder, value, error, handleChange, classIcon, label, options, formClass
}) => {
    return (
        <Form.Group>
            {!!label && 
            <Form.Label>
                <i className={classIcon}></i> <span>{label}</span>
            </Form.Label>
            }
            <Form.Control
                className={formClass || undefined}
                as="select"
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                isInvalid={!!error}
            >
                <option value={""}>{placeholder}</option>
                {options.map((option, index) => <option key={index} value={option.value}>{option.label}</option>)}
            </Form.Control>
            {!!label && 
            <Form.Control.Feedback type="invalid">
                {error}
            </Form.Control.Feedback>
            }
        </Form.Group>
    )

}
export default FormSelect;