import React, { useEffect, useState } from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import FormSelect from "components/FormSelect";
import FormText from "components/FormText";
import cashingService from "services/cashingService";
import { useParams } from "react-router-dom";
import { showError } from "utils/error";
import { formatDate } from "utils/pipes/formatter";
import { Link } from "react-router-dom";
import RemoveCashMovementModal from "./RemoveCashMovementModal";
import { useProfil } from "contexts/ProfilContext";

const CashMovementLine = ({ cashMovement, options, loadData }) => {
    const { projectId } = useParams();
    const { isAdmin } = useProfil();
    const [formData, setFormData] = useState({
        movement_date: cashMovement.movement_date || new Date().toISOString().split('T')[0],
        member_id: cashMovement.member_id,
        cash_register_id: cashMovement.cash_register_id,
        cash_movement_type_id: cashMovement.cash_movement_type_id,
        product: cashMovement.product,
        supplier_id: cashMovement.supplier_id,
        quantity: cashMovement.quantity,
        unit_price: cashMovement.unit_price,
        total_inflow: cashMovement.total_inflow || 0,
        total_outflow: cashMovement.total_outflow || 0
        
    });
    const [errors, setErrors] = useState({});
    const [optionsMembers, setOptionsMembers] = useState([]);
    const [optionsSuppliers, setOptionsSuppliers] = useState([]);
    const [optionsCashRegisters, setOptionsCashRegisters] = useState([]);
    const [optionsCashMovementTypes, setOptionsCashMovemenTypes] = useState([]);
    const [cashMovementTypes, setCashMovementsTypes] = useState(options.cash_movements_types);

    
    useEffect(() => {

        setOptionsMembers(options.members.map(member => ({ label: `${member.first_name} ${member.last_name}`, value: member.id })));
        setOptionsSuppliers(options.suppliers.map(supplier => ({ label: supplier.name, value: supplier.id })));
        setOptionsCashRegisters(options.cash_registers.map(cashRegister => ({ label: cashRegister.name, value: cashRegister.id })));
        setOptionsCashMovemenTypes(options.cash_movements_types.map(cashMovementType => ({ label: cashMovementType.name, value: cashMovementType.id })));
        setCashMovementsTypes(options.cash_movements_types);

    }, [options]);

    const handleChange = (event) => {
        const { name, value } = event.target;
    
        setFormData((prevData) => {
            const updatedFormData = {
                ...prevData,
                [name]: value,
            };
            if (["quantity", "unit_price", "cash_movement_type_id"].includes(name)) {
                return updateFlow(updatedFormData);
            }
            return updatedFormData;
        });
    };

    const handleUpdate = () => {
        cashingService.updateCashMovement(formData, projectId, cashMovement.id)
        .then(result => {
            setOnInput(false);
            loadData();
        })
        .catch(error => setErrors(error.response.data.errors));
    }

    const [onInput, setOnInput] = useState(false);

    const updateFlow = (updatedFormData) => {
        const cashMovementType = cashMovementTypes.find(
            (element) => element.id === Number(updatedFormData.cash_movement_type_id)
        );
        if (cashMovementType) {
            const isOutflow = cashMovementType.movement < 10;
            return {
                ...updatedFormData,
                total_inflow: isOutflow ? 0 : updatedFormData.quantity * updatedFormData.unit_price,
                total_outflow: isOutflow ? updatedFormData.quantity * updatedFormData.unit_price : 0,
            };
        }
        return updatedFormData;
    };

    return (
        <tr className={onInput ? "input-line" : ""}>
            {onInput ? (
                <>
                    <td>
                        <FormText
                            type="date"
                            handleChange={handleChange}
                            name="movement_date"
                            value={formData.movement_date}
                            placeholder="Date de mouvement"
                            error={showError(errors, "movement_date")}
                        />
                    </td>
                    <td>
                        <FormSelect
                            name="member_id"
                            value={formData.member_id}
                            placeholder="Responsable"
                            handleChange={handleChange}
                            options={optionsMembers}
                            error={showError(errors, "member_id")}
                    />
                    </td>
                    <td>
                        <FormSelect
                            name="cash_register_id"
                            value={formData.cash_register_id}
                            placeholder="Caisse"
                            handleChange={handleChange}
                            options={optionsCashRegisters}
                            error={showError(errors, "cash_register_id")}
                        />
                    </td>
                    <td>
                        <FormSelect
                            name="cash_movement_type_id"
                            value={formData.cash_movement_type_id}
                            placeholder="Type"
                            handleChange={handleChange}
                            options={optionsCashMovementTypes}
                            error={showError(errors, "cash_movement_type_id")}
                        />
                    </td>
                    <td>
                        <FormText
                            name="product"
                            value={formData.product}
                            placeholder="Produit"
                            handleChange={handleChange}
                            error={showError(errors, "product")}
                        />
                    </td>
                    <td>
                        <FormSelect
                            name="supplier_id"
                            value={formData.supplier_id}
                            placeholder="Fournisseur"
                            handleChange={handleChange}
                            options={optionsSuppliers}
                            error={showError(errors, "supplier_id")}
                        />
                    </td>
                    <td>
                        <FormText
                            name="quantity"
                            value={formData.quantity}
                            placeholder="Quantité"
                            handleChange={handleChange}
                            error={showError(errors, "quantity")}
                        />
                    </td>
                    <td>
                        <FormText
                            name="unit_price"
                            value={formData.unit_price}
                            placeholder="Prix Unitaire"
                            handleChange={handleChange}
                            error={showError(errors, "unit_price")}
                        />
                    </td>
                    <td>
                        <FormText
                            name="total_inflow"
                            value={formData.total_inflow}
                            placeholder="Entrée total"
                            handleChange={handleChange}
                            disabled={true}
                        />
                    </td>
                    <td>
                        <FormText
                            name="total_outflow"
                            value={formData.total_outflow}
                            placeholder="Sortie Total"
                            handleChange={handleChange}
                            disabled={true}
                        />
                    </td>
                    <td className="action-input">
                        <div className="asako-btn-group">
                            <button className="asako-btn-icon asako-btn-primary" onClick={() => handleUpdate()}>
                                <i className="bi bi-check2-all"></i>
                            </button>
                            <button className="asako-btn-icon asako-btn-secondary" onClick={() => setOnInput(false)}>
                                <i className="bi bi-x-lg"></i>
                            </button>
                        </div>
                    </td>
                </>
            ) : (
                <>
                    <td className="movement_date">{formatDate(cashMovement.movement_date)}</td>
                    <td className="responsable">{cashMovement.member_first_name}</td>
                    <td className="cash_register">{cashMovement.cash_register_name || "N/A"}</td>
                    <td className="type"><Badge>{cashMovement.cash_movement_type_name || "N/A"}</Badge></td>
                    <td className="product">{cashMovement.product || "N/A"}</td>
                    <td className="supplier">
                        <Link to={`/projects/${projectId}/suppliers/${cashMovement.supplier_id}`}>
                        {cashMovement.supplier_name}
                        </Link>
                    </td>
                    <td className="quantity">{cashMovement.quantity}</td>
                    <td className="unit_price">{cashMovement.unit_price.toLocaleString()}</td>
                    <td className="total_inflow">{cashMovement.total_inflow.toLocaleString()}</td>
                    <td className="total_outflow">{cashMovement.total_outflow.toLocaleString()}</td>
                    {isAdmin() && 
                    <td className="action">
                        <div className="asako-btn-group">
                            <OverlayTrigger
                                placement="left"
                                delay={{ show: 250, hide: 400 }}
                                overlay={tooltipValidate}
                            >
                                <button className="asako-btn-icon asako-btn-primary" onClick={() => setOnInput(true)}>
                                    <i className="bi bi-pencil-fill"></i>
                                </button>
                            </OverlayTrigger>
                            <RemoveCashMovementModal cashMovement={cashMovement} loadData={loadData}/>
                        </div>
                    </td>
                    }
                </>
            )}
        </tr>
    );
};

const tooltipValidate = (
    <Tooltip id="tooltip">
        Modifier
    </Tooltip>
);



export default CashMovementLine;
