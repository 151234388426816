import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import teamService from "services/teamService";
import {useParams} from "react-router-dom";

const RemoveMemberModal = ({ member, loadData }) => {
    const { projectId } = useParams();

    // State pour montrer ou non le modal
    const [show, setShow] = useState(false);

    // Montrer le modal
    const handleShow = () => setShow(true);

    // Fermer le modal
    const handleClose = () => setShow(false);

    // Submitter le formulaire
    const handleSubmit = (e) => {
        e.preventDefault();
        teamService.deleteMember(member.id, projectId)
        .then(result => {
            handleClose();
            loadData();
        })
        .catch(error => console.error(error));
    }

    return (
        <>
            <button 
            className="asako-btn-icon asako-btn-danger"
            onClick={handleShow}
            >
                <i className="bi bi-trash-fill"></i>
            </button>
        
            <Modal show={show} centered dialogClassName="asako__modal delete-member__modal">
                <Modal.Header className="header">
                    <Modal.Title>
                        <h4>Enlever un nouveau membre</h4>
                    </Modal.Title>
                    <i className="bi bi-x-lg" onClick={handleClose}></i>
                </Modal.Header>
                <Modal.Body className="delete-body">
                    <p>Êtes-vous sûres de vouloir enlever <span> {member.name}</span> du projet?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={handleSubmit} className="asako-btn asako-btn-danger">Enlever</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default RemoveMemberModal;
