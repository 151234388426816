import React from "react";

import { Card, Col, Row } from "react-bootstrap";

const MemberLoader = () => {

    return (
        <>
            <Card className="member-card-loader mb-4">
                <Card.Body className="body">
                    <div >
                        <Row className="member-information mb-3">
                            <Col md={3}>
                                <div className="member-image">
                                    <i className="bi bi-person"></i>
                                </div>
                            </Col>
                            <Col md={9}>
                                <div className="member-name-phone">
                                    <div className="name"></div>
                                    <div className="profil"></div>
                                </div>
                            </Col>
                        </Row>
                        <hr/>
                        <div className="other">
                            <div className="roles">
                            </div>
                            <div className="mail">
                            </div>
                            <div className="facebook">
                            </div>
                            <div className="phone">
                            </div>
                        
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    ) 
}
export default MemberLoader;