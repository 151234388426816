import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";

import CreatableSelect from 'react-select/creatable';
import productService from "services/productService";
import { formatMGA } from "utils/NumberFormatter";
import { useParams } from "react-router-dom";

const StepSupplierProduct = ({supplierProductsData, setSupplierProductsData, errors}) => {
    const { projectId } = useParams();
    const [productOptions, setProductOptions] = useState([]);
    const [formNotice, setFormNotice] = useState("");
    const [formData, setFormData] = useState({
        name: null,
        description: "",
        notices: [],
        unit_price: 0
    });

    const selectProductRef = useRef(null);
    const handleSelectChange = (selectedOption, actionMeta) => {
        setFormData({
            ...formData,
            name: selectedOption
        });
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleAddProduct = () => {
        if (formData.name === null) return;
        const product = {
            name: formData.name?.value || "",
            description: formData.description,
            notices: formData.notices,
            unit_price: formData.unit_price
        };
        setSupplierProductsData([...supplierProductsData, product]);
        setFormData({
            name: null,
            description: "",
            notices: [],
            unit_price: 0
        });
        selectProductRef.current.focus();
    }

    const handleDeleteProduct = (index) => {
        const temp = [...supplierProductsData];
        temp.splice(index, 1);
        setSupplierProductsData(temp);
    }

    useEffect(() => {
        productService.getProductByProject(projectId)
        .then(result => {
            const options = result.map(item =>  ({
                value: item.name,
                label: item.name
            }));
            setProductOptions(options);
        })
        .catch(error => console.error(error));
    }, [projectId]);


    const deleteNotice = (index) => {
        const temp = [...formData.notices];
        temp.splice(index, 1);
        setFormData({
            ...formData,
            notices: temp
        });
    }

    const addNotice = () => {
        if (formNotice === null) return;
        setFormData({
            ...formData,
            notices: [...formData.notices, formNotice]
        })
        setFormNotice("");
    }

    const showError = (field) => {
        if (errors[field] && errors[field].length > 0) {
          return errors[field][0]; 
        }
        return null;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleAddProduct();
    }

    return (
        <>
            <Row>
                <Col md={4}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="StepSupplierInformationName" className="mb-2">
                            <Form.Label>
                                <span> <i className="bi bi-cart"></i> </span>
                                Produit
                            </Form.Label>
                            <CreatableSelect
                                isClearable
                                options={productOptions}
                                onChange={handleSelectChange}
                                name="name"
                                value={formData.name}
                                autoFocus
                                formatCreateLabel={(inputValue) => `Nouveau "${inputValue}"`}
                                ref={selectProductRef}
                                />
                        </Form.Group>

                        <Form.Group controlId="StepSupplierInformationName" className="mb-2">
                            <Form.Label>
                                <span> <i className="bi bi-info-circle"></i> </span>
                                Description
                            </Form.Label>
                            <Form.Control
                            as="textarea"
                            rows={6}
                            placeholder="description"
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="StepSupplierInformationUnitPrice" className="mb-2">
                            <Form.Label>
                                <span> <i className="bi bi-credit-card"></i> </span>
                                Prix
                            </Form.Label>
                            <InputGroup >
                                <Form.Control
                                type="number"
                                placeholder="Prix"
                                name="unit_price"
                                value={formData.unit_price}
                                onChange={handleInputChange}
                                min={0}
                                step={0.01}
                                isInvalid={!!showError('unit_price')}
                                />
                                <InputGroup.Text id="InputGroupUnitPrice">Ar</InputGroup.Text>
                                <Form.Control.Feedback type="invalid">
                                    {showError('unit_price')}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group controlId="NoticeForm" className="mb-3">
                            <Form.Label>
                                <span> 
                                    <i className="bi bi-chat-dots"></i> Vos avis
                                </span>
                            </Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control
                                value={formNotice}
                                onChange={e => setFormNotice(e.target.value)}
                                placeholder="Nouvel avis ..."
                                autoFocus
                                />
                                <Button variant="outline-secondary" id="button-addon2" onClick={addNotice}>
                                Ajouter
                                </Button>
                            </InputGroup>
                            <ul>
                                {formData.notices.map((item, index) => <li key={index}> <i className="bi bi-x-lg" onClick={() => deleteNotice(index)}></i> {item}</li>)}
                            </ul>
                        </Form.Group>
                        <button className="asako-btn asako-btn-primary w-100">Ajouter</button>
                    </Form>
                </Col>
                <Col md={8}>
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <th>Produit</th>
                                <th>Détails</th>
                                <th>Prix</th>
                                <th>Avis</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {supplierProductsData.map((product, index)=> 
                            <tr key={index}>
                                <td>{product.name}</td>
                                <td>{product.description}</td>
                                <td>{formatMGA(product.unit_price)}</td>
                                <td>
                                    <ul>
                                        {product.notices.map((notice, index) => <li key={index}>{notice}</li>)}
                                    </ul>
                                </td>
                                <td>
                                    <button className="asako-btn-icon asako-btn-danger" onClick={() => handleDeleteProduct(index)}><i className="bi bi-x-lg"></i></button>
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    );
}

export default StepSupplierProduct;