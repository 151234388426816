import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import cashingService from "services/cashingService";
import CashMovementWidget from "./CashMovementWidget";

const CashMovementDashboard = (loadData) => {
    const { projectId } = useParams();
    const [dashboard, setDashboard] = useState({
        total_inflow: 0,
        total_outflow: 0,
        total_profit: 0,
    });

    useEffect(() => {
        cashingService.getProjectFlow(projectId)
        .then(result => {
            setDashboard({
                total_inflow: result.total_inflow,
                total_outflow: result.total_outflow,
                total_profit: result.total_profit,
            })
        })
    }, [projectId, loadData]);

    return (
        <div className="mb-4">
            <CashMovementWidget name={"Entrée Total"} value={`${dashboard.total_inflow.toLocaleString()} Ar`} />
            <CashMovementWidget name={"Sortie Total"} value={`${dashboard.total_outflow.toLocaleString()} Ar`} />
            <CashMovementWidget name={"Caisse Total"} value={`${dashboard.total_profit.toLocaleString()} Ar`} />
        </div>
    )
}

export default CashMovementDashboard;