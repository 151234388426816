/**
 * Formatte un nombre au format 1 000 000 MGA
 * 
 * @param {Number} number 
 * @returns Version formatté
 */
export function formatMGA (number) {
    const data = Number(number);
    const formattedPrice = data
    .toFixed(0) 
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ') 
    .replace(/,/g, '.'); 

    return `${formattedPrice}`;
}