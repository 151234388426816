import { useState } from "react";
import React from "react";
import ContactEmail from "./ContactEmail";
import ContactPhone from "./ContactPhone";
import { Tab, Tabs } from "react-bootstrap";
import "../../assets/css/client/home/contact.css";

const Contact =() => {
    
    const [key, setKey] = useState('contact-mail')

    return (
        <>
            <div className="contact" >
                <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3 contact__tabs"
                >
                    <Tab eventKey="contact-mail" title="Contacter par mail" tabClassName={key === "contact-mail" ? "contact__tab--active" : "contact__tab"}>
                        <ContactEmail />
                    </Tab>
                    <Tab eventKey="contact-phone" title="Contacter par téléphone" tabClassName={key === "contact-phone"  ? "contact__tab--active" : "contact__tab"}> 
                        <ContactPhone />
                    </Tab>
                </Tabs>
            </div>
        </>
    )

}
export default Contact