// src/pages/Login.js

import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "../../../assets/css/client/auth/login.scss"; 
import clientService from "services/clientService";

const Login = () => {
    const [mail, setMail] = useState("");
    const [password, setPassword] = useState("");
    const history = useHistory();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const isLoggedIn = await clientService.login(mail, password);
            if (isLoggedIn) {
                history.push('/projects'); 
            }
        } catch (error) {
            const errorMessage = error.response?.data?.error || "Information de connexion erronée";
            alert(errorMessage);
        }
    }
    
    return (
    <div className="login__container">
        <a href="home" className="home">Accueil</a>
        <div className="login__image">
            <img src="./assets/img/login.png" alt="login background"/>
        </div>
        <div className="login__form--container">
            <div className="parent">
                <h2 className="text-center mb-4">Se connecter</h2>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formBasicEmail" className="login__form--input">
                    <label>
                        <i className="bi bi-envelope"></i>
                        Email
                    </label>
                    <Form.Control
                        type="email"
                        placeholder="Entrer votre email"
                        value={mail}
                        name="mail"
                        onChange={(e) => setMail(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword" className="login__form--input">
                    <label>
                        <i className="bi bi-lock"></i>
                        Mot de passe
                    </label>
                    <Form.Control
                        type="password"
                        name="password"
                        placeholder="Entrer votre mot de passe"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>
                    <button className="login__submit" type="submit">
                        Se connecter
                    </button>
                    <div className="forgotPassword">
                        <a href="forgot-password">Mot de passe oublié ?</a>
                    </div>
                </Form>
                <hr />
                <p className="nousContacter">Si vous n'avez pas encore de compte, veuillez <span>nous contacter</span></p>
                <button className="login__submit">
                    Nous contacter
                </button>
            </div>
        </div> 
    </div>
  );
};

export default Login;
