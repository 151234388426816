import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import projectService from "services/projectService";
import '../../assets/css/admin/project.scss';

const ModifyProjectModal = ({data, loadData}) => {
    const initialData = data;
    const [name, setName] = useState(data.name);
    const [description, setDescription] = useState(data.description);
    const [type, setType] = useState(data.project_type_id);
    const [errors, setErrors] = useState({
        name: [],
        description: [],
        type: []
    });
    
    const [types, setTypes] = useState([]);

    useEffect(() => {
        projectService.getAllTypeProject()
        .then(result => setTypes(result))
        .catch(error => console.error(error));
    }, []);
   
    const [show, setShow] = useState(false);


    const handleClose = () => {
        setShow(false);
        initValue();
    };
    const handleShow = () => setShow(true);

    const showError = (field) => {
        if (errors[field] && errors[field].length > 0) {
          return errors[field][0]; 
        }
        return null;
    };

    const initValue = () => {
        setName(initialData.name);
        setDescription(initialData.description);
        setType(initialData.type);
        setErrors({});
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            name: name,
            description: description
        };
        projectService.updateProject(data, initialData.id)
        .then(result => {
            loadData();
            setShow(false);
        })
        .catch(error => setErrors(error.response.data.errors));
    }


    return (
        <>
            <button className="asako-btn-icon asako-btn-primary mr-2" onClick={handleShow}>
                <i className="bi bi-gear-fill"></i>
            </button>

            <Modal show={show} onHide={handleClose} centered dialogClassName="asako__modal">
                <Form onSubmit={handleSubmit}>
                    <Modal.Header className="header">
                        <Modal.Title>{initialData.name}</Modal.Title>
                        <i className="bi bi-x-lg" onClick={handleClose}></i>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="UpdateProjectFormName" className="project__form--group mb-3">
                        <Form.Label>
                            <span> <i className="bi bi-house"></i> </span>
                            Nom du projet
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nom du projet"
                            name="text"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            className="project__form--input"
                            isInvalid={!!showError('name')}
                        />
                        <Form.Control.Feedback type="invalid">
                            {showError('name')}
                        </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="UpdateProjectFormType" className="project__form--group mb-3">
                            <Form.Label className="project__form--label">
                                <span> <i className="bi bi-tags"></i> </span>
                                Type de projet
                            </Form.Label>
                            <Form.Select 
                                className="form-control project__form--input"
                                value={type}
                                onChange={e => setType(e.target.value)}
                                >
                                {types.map((item) => 
                                    <option value={item.id} key={item.id} >{item.name}</option>
                                )}                            
                            </Form.Select>
                        </Form.Group>

                        <Form.Group controlId="UpdateProjectFormDescription" className="project__form--group mb-3">
                        <Form.Label>
                            <span> <i className="bi bi-info-circle"></i> </span>
                            Description
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="description"
                            className="project__form--input"
                            placeholder="Description"
                            as="textarea"
                            rows="5"
                            value={description}
                            isInvalid={!!showError('description')}
                            onChange={e => setDescription(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {showError('description')}
                        </Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="asako-btn asako-btn-secondary" onClick={handleClose} type="button">
                            Fermer
                        </button>
                        <button className="asako-btn asako-btn-primary" type="submit">
                            Sauvegarder
                        </button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default ModifyProjectModal;