import React from "react";
import { Form } from "react-bootstrap";

const ContactEmail = () => {
    return (
        <>

            <Form className="contact__tab--mail"> 
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Votre email</Form.Label>
                    <Form.Control type="email" placeholder="nom@exemple.com" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Votre question</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                </Form.Group>
                <button className="contact__tab--mail-submit">Envoyer</button>
            </Form>
        </>
    )
}
export default ContactEmail