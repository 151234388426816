import React from "react";

import '../../assets/css/admin/project.scss';
import { useHistory } from "react-router-dom";
import ModifyProjectModal from "./ModifyProjectModal";
import DeleteProjectModal from "./DeleteProjectModal";
import { useProfil } from "contexts/ProfilContext";
import clientService from "services/clientService";

const Project = ({ data, loadData }) => {
    const { setProfil, isAdmin } = useProfil(); 
    const history = useHistory();
    const chooseProject = () => {
        setProfil({
            id: data.profil_id,
            name: data.profil,
        })
        history.push(`/projects/${data.id}/dashboard`);
    }

    return (
        <>
            <div className="card project__card mb-3 mr-3" onClick={chooseProject}>
                <div className="card-header project__card--header">
                    <h4>{data.name}</h4>
                </div>
                <div className="card-body">
                    <h5>{data.project_type}</h5>
                    <p>{data.description}</p>
                </div>
                <div className="card-footer project__card--footer">
                    {isAdmin(data.profil_id) &&
                    <>
                        <ModifyProjectModal data={data} loadData={loadData}/>
                        <DeleteProjectModal data={data} loadData={loadData}/>
                    </> 
                    }
                </div>
            </div>
        </>
    );
}

export default Project;