import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import supplierService from "services/supplierService";

const DeleteSupplierProductModal = ({product, supplier, loadData}) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const handleDelete = () => {
        supplierService.deleteSupplierProduct(supplier.id, product.id)
        .then(result => {
            loadData();
            handleClose();
        })
        .catch(error => console.error(error));
    }
    
    return (
        <>
            <button className="asako-btn-icon asako-btn-danger" onClick={handleShow}>
                <i className="bi bi-trash-fill"/>
            </button>

            <Modal show={show} onHide={handleClose} centered dialogClassName="asako__modal deleting">
                <Modal.Header className="header">
                    <h4>{product.name}</h4>
                    <i className="bi bi-x-lg" onClick={handleClose}></i>
                </Modal.Header>
                <Modal.Body>
                    <p>Êtes-vous sûrs de vouloir enlever <span className="product-deleted">{product.name}</span> des produits vendues par <span className="supplier">{supplier.name}</span></p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="asako-btn-secondary asako-btn" onClick={handleClose}>Annuler</button>
                    <button className="asako-btn-danger asako-btn" onClick={handleDelete}>Supprimer</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default DeleteSupplierProductModal;