import DeletingNoticeTooltip from "components/DeletingNoticeTooltip";
import React, { useState } from "react";
import { Button, Form, InputGroup, Modal, OverlayTrigger } from "react-bootstrap";
import supplierService from "services/supplierService";

const UpdateSupplierProductModal = ({oldData, supplier_id, loadData}) => {
    const [formNotice, setFormNotice] = useState("");
    const [formData, setFormData] = useState({
        description: "",
        notices: []
    });
    const [errors, setErrors] = useState({});
    const [show, setShow] = useState(false);

    const handleShow = () => {
        setShow(true);
        setFormData({
            ...formData,
            description: oldData.description || "",
            notices: oldData.notices,
            unit_price: oldData.unit_price
        });    
        setFormNotice("");
    }

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value
        }); 
    }

    const handleClose = () => {
        setShow(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        supplierService.updateSupplierProduct(formData, supplier_id, oldData.id)
        .then(result => {
            loadData();
            handleClose();
        })
        .catch(error => setErrors(error.response.data.errors));
    }

    const deleteNotice = (index) => {
        const temp = [...formData.notices];
        temp.splice(index, 1);
        setFormData({
            ...formData,
            notices: temp
        });
    }

    const addNotice = () => {
        setFormData({
            ...formData,
            notices: [...formData.notices, formNotice]
        })
        setFormNotice("");
    }


    const showError = (field) => {
        if (errors[field] && errors[field].length > 0) {
          return errors[field][0]; 
        }
        return null;
    };

    return (
        <>
            <button className="asako-btn-icon asako-btn-primary" onClick={handleShow}>
                <i className="bi bi-pencil-fill"></i>
            </button>

            <Modal show={show} onHide={handleClose} centered dialogClassName="asako__modal">
                <Modal.Header className="header">
                    <Modal.Title>
                        <h4>{oldData.name}</h4>
                    </Modal.Title>
                    <i className="bi bi-x-lg" onClick={handleClose}></i>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="DescriptionForm" className="mb-3">
                            <Form.Label>
                                <span> 
                                    <i className="bi bi-info-circle"></i> Description
                                </span>
                            </Form.Label>
                            <Form.Control
                            as="textarea"
                            rows={6}
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            placeholder="description"
                            autoFocus
                            />
                        </Form.Group>


                        <Form.Group controlId="StepSupplierInformationUnitPrice" className="mb-2">
                            <Form.Label>
                                <span> <i className="bi bi-credit-card"></i> </span>
                                Prix
                            </Form.Label>
                            <InputGroup >
                                <Form.Control
                                type="number"
                                placeholder="Prix"
                                name="unit_price"
                                value={formData.unit_price}
                                onChange={handleChange}
                                min={0}
                                step={2}
                                pattern="\d*"
                                isInvalid={!!showError('unit_price')}
                                />
                                <InputGroup.Text id="InputGroupUnitPrice">Ar</InputGroup.Text>
                                <Form.Control.Feedback type="invalid">
                                    {showError('unit_price')}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group controlId="NoticeForm" className="mb-3 form-notice">
                            <Form.Label>
                                <span> 
                                    <i className="bi bi-chat-dots"></i> Vos avis
                                </span>
                            </Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control
                                value={formNotice}
                                onChange={e => setFormNotice(e.target.value)}
                                placeholder="Nouvel avis ..."
                                autoFocus
                                />
                                <Button variant="outline-secondary" id="button-addon2" onClick={addNotice}>
                                Ajouter
                                </Button>
                            </InputGroup>
                            <div>
                                <ul>
                                    {formData.notices.map((item, index) => 
                                        <li key={index}> 
                                        <OverlayTrigger
                                            placement="left"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={DeletingNoticeTooltip}
                                        >
                                            <i className="bi bi-x-lg" onClick={() => deleteNotice(index)}></i> 
                                        </OverlayTrigger>
                                            {item}
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </Form.Group>
                        <button className="asako-btn asako-btn-primary" type="submit" >
                            Terminer
                        </button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );


   
}
export default UpdateSupplierProductModal;