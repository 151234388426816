import React, { useEffect, useState } from "react";
import '../../assets/css/admin/project-page.scss';
import Project from "../../components/admin/Project";
import AddProjectModal from "../../components/admin/AddProjectModal";
import projectService from "services/projectService";

const ProjectPage = () => {
    const [projects, setProjects] = useState([]);
    const [nbProject, setNbProject] = useState(0);

    const loadData = () => {
        projectService.getClientConnectedProject()
        .then(result => {
            setProjects(result.projects);
            setNbProject(result.nb_project);
        })
        .catch(error => console.error(error));
    }

    useEffect(() => {
        loadData();
    }, [])

    return (
        <>
            <div className="project">
                <h1>Configuration <span>des projets</span></h1>
                <AddProjectModal loadData={loadData}/>
                
                <h3 className="mt-3 mb-3">Vos projets (<span>{nbProject}</span>)</h3>
                <div className="row">

                {projects.map((project, index) => 
                    <Project data={project} loadData={loadData} key={index}/>
                )}
                </div>
            </div>
        </>
    );
}

export default ProjectPage;
