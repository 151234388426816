import React from "react"

import { useEffect, useState } from "react"
import OfferBox from "./OfferBox"
import "../../assets/css/client/home/offer.css"

const Offer = () => {

    const [offers, setOffers] = useState(fakeData)
    useEffect(() => {
        setOffers(fakeData)
    }, [])

    return (
        <>
            <div className="offer-content">
                <div className="offers">
                    {offers.map((offer, index) => 
                        <OfferBox data={offer} key={index}/>
                    )}
                </div>
            </div>
        </>
    )

}
export default Offer

const fakeData = [
    {
        name: "Perso",
        products: [ "5go","8go RAM","Traitement long","faible","Lorem Ipsum","data1","data1","data1","data1","data1"  ]
    }, 
    {
        name: "Pro",
        products: [ "25go","500 utilisateurs","Rapide" ]
    }, 
    {
        name: "Premium",
        products: [ "100go","plus de 2 000 utilisateur simultanés" ]
    }
]
