// src/pages/sign.js

import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Spinner  } from "react-bootstrap";
import "../../../assets/css/client/auth/sign.css"; 
import clientService from "services/clientService";

const Sign = () => {
    const [mail, setMail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [contact, setContact] = useState("");
    const [primaryPhone, setPrimaryPhone] = useState("");
    const [secondaryPhone, setSecondaryPhone] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({});

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = {
            "mail": mail,
            "first_name": firstName,
            "last_name": lastName,
            "primary_phone": primaryPhone,
            "secondary_phone": secondaryPhone,
            "contact": contact, 
            "password": password
        }
        try {
            const response = await clientService.register(data);
            if (response) {
                alert("Votre inscription a été effectué");
            } 
        } catch (error) {
            setErrors(error.response.data.errors);
        }
    }
    
    return (
    <div className="sign__container">
        <div className="sign__image">
            <img src="" alt="sign background"/>
        </div>
        <div className="sign__form--container">
            <h1>Asa-ko</h1>
            <h2 className="text-center mb-4">S'inscrire</h2>

            {/* ERROR START */}
            {Object.keys(errors).length > 0 && (
                <ul className="error-list" style={{ color: "red" }}>
                    {Object.entries(errors).map(([key, messages]) => (
                        <li key={key}>
                            {messages.map((message, index) => (
                                <div key={index}>{message}</div>
                            ))}
                        </li>
                    ))}
                </ul>
            )}
            {/* ERROR END */}


            <Form onSubmit={handleSubmit}>
                <Container>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="formBasicPassword" className="sign__form--input">
                            <Form.Control
                                type="text"
                                name="first_name"
                                placeholder="Prénom"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                />
                            </Form.Group>
                        </Col>

                        <Col md={6}>
                            <Form.Group controlId="formBasicPassword" className="sign__form--input">
                            <Form.Control
                                type="text"
                                name="last_name"
                                placeholder="Nom"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="formBasicPassword" className="sign__form--input">
                            <Form.Control
                                type="text"
                                name="primary_phone"
                                placeholder="Téléphone Principale"
                                value={primaryPhone}
                                onChange={(e) => setPrimaryPhone(e.target.value)}
                                />
                            </Form.Group>
                        </Col>

                        <Col md={6}>
                            <Form.Group controlId="formBasicPassword" className="sign__form--input">
                            <Form.Control
                                type="text"
                                name="secondary_phone"
                                placeholder="Téléphone secondaire"
                                value={secondaryPhone}
                                onChange={(e) => setSecondaryPhone(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group controlId="formBasicEmail" className="sign__form--input">
                    <Form.Control
                        type="email"
                        placeholder="Email"
                        value={mail}
                        name="mail"
                        onChange={(e) => setMail(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword" className="sign__form--input">
                    <Form.Control
                        type="password"
                        name="password"
                        placeholder="Mot de passe"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>
                    <button className="sign__submit" type="submit">
                        S'inscrire
                    </button>
                    <a href="login">Se Connecter</a>
                </Container>
            </Form>
        </div> 
    </div>
  );
};

export default Sign;
