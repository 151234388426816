import axiosInstance from 'config/axiosConfig'; 

const productService = {

    /**
     * Récupère tous les produits
     * 
     * @returns Tous les types de produits
     */
    getAllProduct: async () => {
        const response = await axiosInstance.get('v1/products');
        return response.data
    },

    
    /**
     * Récupère tous les produits
     * 
     * @returns Tous les types de produits
     */
    getProductByProject: async (projectId) => {
        const response = await axiosInstance.get(`v1/projects/${projectId}/products`);
        return response.data
    },

    



}


export default productService;