import React from "react";
import { Col, Row, Table } from "react-bootstrap";

const RecapStepAddSupplier = ({ information, products }) => {
    return (
        <>
            <Row>
                <Col md={4}>
                    <p><strong>Name:</strong> {information.name}</p>
                    <p><strong>Location:</strong> {information.location}</p>
                    <p><strong>Contact:</strong></p>
                        <ul>
                            <li><strong>Téléphone:</strong> {information.phone}</li>
                            <li><strong>Facebook:</strong> {information.facebook_username}</li>
                        </ul>
                    <p><strong>Heure d'ouverture: </strong> {information.opening_time}</p>
                </Col>
                <Col md={8}>
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <th>Produit</th>
                                <th>Détails</th>
                                <th>Prix</th>
                                <th>Avis</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map((product, index)=> 
                            <tr key={index}>
                                <td>{product.name}</td>
                                <td>{product.description}</td>
                                <td>{product.unit_price}</td>
                                <td>
                                    <ul>
                                        {product.notices.map((notice, index) => <li key={index}>{notice}</li>)}
                                    </ul>
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}

export default RecapStepAddSupplier;