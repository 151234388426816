import React from "react";

import { Tooltip } from "react-bootstrap"

const DeletingNoticeTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props} >
    Effacer
  </Tooltip>
);

export default DeletingNoticeTooltip;