import React, { useCallback, useEffect, useState } from "react";      
import { Table } from "react-bootstrap";
import NewCashRegisterModal from "./NewCashRegisterModal";
import { useParams } from "react-router-dom";
import cashRegisterService from "services/cashRegisterService";
import ModifyCashRegisterModal from "./ModifyCashRegisterModal";
import AsakoPagination from "components/AsakoPagination";

const CashRegisterCRUD = () => {
    const { projectId } = useParams();
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({
        per_page: 1,
        total: 0,
        current_page: 1,
        last_page: 1
    });
    const [cashRegisterData, setCashRegisterData] = useState([]);

    const loadData = useCallback(() => {
        cashRegisterService.getPaginatedCashRegisterByProject(projectId, page)
        .then(result => {
            setCashRegisterData(result.data);
            setPagination(result);
        })
        .catch(error => console.error(error));
    }, [projectId, page]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <>
            <h1>Listes des caisses</h1>
            <Table striped className="cash-register">
                <thead>
                    <tr>
                        <th className="index">#</th>
                        <th className="name">Nom</th>
                        <th className="action"><NewCashRegisterModal loadData={loadData} /></th>
                    </tr>
                </thead>
                <tbody>
                    {cashRegisterData.map((cashRegister, index) => 
                    <tr key={cashRegister.id}>
                        <td className="index">{(pagination.per_page * (pagination.current_page - 1)) + index + 1}</td>
                        <td className="name">
                            {cashRegister.name}
                        </td>
                        <td className="action">
                            <ModifyCashRegisterModal loadData={loadData} cashRegister={cashRegister}/>
                        </td>
                    </tr>
                    )}
                </tbody>
            </Table>
            <AsakoPagination page={page} setPage={setPage} pagination={pagination} />
        </>
    );
}
export default CashRegisterCRUD;