import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import CashRegisterForm from "./CashRegisterForm";
import cashRegisterService from "services/cashRegisterService";
import { formatCashRegisterForm } from "utils/FormFormatter";
import { useParams} from "react-router-dom";

const NewCashRegisterModal = ({loadData}) => {
    const { projectId } = useParams();
    const [formData, setFormData] = useState({
        name: ""
    });
    const [errors, setErrors] = useState({});

    const [show, setShow] = useState();

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const initValue = () => {
        setFormData({
            name: ""
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        cashRegisterService.saveNewCashRegister(formatCashRegisterForm(formData), projectId)
        .then(() => {
            loadData();
            handleClose();
            initValue();
        })
        .catch(error => error.response.status === 422 ? setErrors(error.response.data.errors) : console.error(error));
    }

    return (
        <>
            <button 
            className="asako-btn-icon asako-btn-primary"
            onClick={handleShow}
            >
            
                <i className="bi bi-plus-lg"></i>
            </button>

            <Modal show={show} centered dialogClassName="asako__modal">
                <Form onSubmit={handleSubmit}>
                    <Modal.Header className="header">
                        <Modal.Title>Ajout de caisse</Modal.Title>
                        <i className="bi bi-x-lg" onClick={handleClose}></i>
                    </Modal.Header>
                    <Modal.Body>
                        <CashRegisterForm formData={formData} setFormData={setFormData} errors={errors}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="asako-btn asako-btn-primary">
                            Ajouter
                        </button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}
export default NewCashRegisterModal;