import React from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";
import ModifyMemberModal from "./ModifyMemberModal";
import RemoveMemberModal from "./RemoveMemberModal";
import { useProfil } from "contexts/ProfilContext";

const MemberCard = ({member, loadData, optionData}) => {

    const { isAdmin } = useProfil();

    return (
        <Card className="member-card mb-4">
            <Card.Body className="body">
                <div >
                    <Row className="member-information mb-3">
                        <Col md={3}>
                            <div className="member-image">
                                <i className="bi bi-person"></i>
                            </div>
                        </Col>
                        <Col md={9}>
                            <div className="member-name-phone">
                                <h4>{`${member.first_name} ${member.last_name}`} </h4>
                                <p className="phone">{member.profil}</p>
                            </div>
                        </Col>
                    </Row>
                    <hr/>
                    <div className="other">
                        <p className="roles">
                            {member.roles.map(role => <Badge bg="secondary" key={role.id} pill className="m-1 role-badge">{role.name}</Badge>)}
                        </p>
                        <p><i className="bi bi-envelope-fill"></i> {member.mail || "Non renseigné"}</p>
                        <p>
                            {member.contact.facebook_username ? 
                            <>
                                <i className="bi bi-facebook"></i> <a href={member.contact.facebook_link} target="_blank" rel="noopener noreferrer">
                                {member.contact.facebook_username || "Non renseigné"}
                                </a>
                            </>
                            :
                            <>
                                <i className="bi bi-facebook"></i> {member.contact.facebook_username || "Non renseigné"}
                            </>
                            }
                        </p>
                        <p><i className="bi bi-phone-fill"></i> {member.contact.phone || "Non renseigné"}</p>
                    </div>
                </div>
            </Card.Body>
            {isAdmin() && 
            <Card.Footer className="footer">
                <ModifyMemberModal member={member} loadData={loadData} optionData={optionData}/>
                <RemoveMemberModal member={member} loadData={loadData}/>
            </Card.Footer>
            }
        </Card>
    );
}
export default MemberCard;