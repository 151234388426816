import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import CashMovementTypeForm from "./CashMovementTypeForm";
import cashMovementTypeService from "services/cashMovementTypeService";
import { formatCashMovementTypeForm } from "utils/FormFormatter";
import { useParams} from "react-router-dom";

const ModifyCashMovementTypeModal = ({loadData, cashMovementType}) => {
    const { projectId } = useParams();
    const [formData, setFormData] = useState({
        name: cashMovementType.name,
        movement: cashMovementType.movement
    });
    const [errors, setErrors] = useState({});
    const [show, setShow] = useState();

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        cashMovementTypeService.updateCashMovementType(formatCashMovementTypeForm(formData), projectId, cashMovementType.id)
        .then(() => {
            loadData();
            handleClose();
        })
        .catch(error => error.response.status === 422 ? setErrors(error.response.data.errors) : console.error(error));
    }

    return (
        <>
            <button 
            className="asako-btn-icon asako-btn-primary"
            onClick={handleShow}
            >
                <i className="bi bi-pencil-fill"></i>
            </button>

            <Modal show={show} centered dialogClassName="asako__modal">
                <Form onSubmit={handleSubmit}>
                    <Modal.Header className="header">
                        <Modal.Title>Modification du type de mouvement de caisse</Modal.Title>
                        <i className="bi bi-x-lg" onClick={handleClose}></i>
                    </Modal.Header>
                    <Modal.Body>
                        <CashMovementTypeForm formData={formData} setFormData={setFormData} errors={errors}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="asako-btn asako-btn-primary">
                            Modifier
                        </button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}
export default ModifyCashMovementTypeModal;