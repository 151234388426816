import axios from 'axios';
import { toast } from "react-toastify";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Authorization': `Bearer ${localStorage.getItem('asakoTokenId')}`
  },
});

axiosInstance.interceptors.response.use(
  response => {
      return response;
  },
  error => {
    if (error.response.status === 401) {
      window.location.href = "/login";
    } else if (error.response.status === 422) {
      toast.error("Erreur de validation");
    }
    else if (error.response) {
      const errorMessage = error.response.data?.message || "Erreur du serveur";
      toast.error(errorMessage);
    } else if (error.request) {
      toast.error("Aucune réponse du serveur");
    } else {
      toast.error(error.message);
    }
    return Promise.reject(error);
  }
);


export default axiosInstance;
